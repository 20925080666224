import orderBy from 'lodash-es/orderBy'
import MarkdownData from '../data/markdown.json'
import CategoriesData from '../components/learn/categories.json'
import { BlogProps } from '../components/learn/blog-card'

const blogMarkdownData: Record<string, Record<string, BlogProps>> = MarkdownData.learn
const blogCategories: Record<string, Record<string, any>> = CategoriesData

/**
 * Get all blogs from "learn" entry of the "data/markdown.json" file
 * @returns array of blogs
 * @type BlogProps[]
 */
export const getAllBlogs = () => {
  const blogs: BlogProps[] = []

  // Iterate through categories under "learn"
  Object.values(blogMarkdownData).forEach((category) => {
    // Iterate through blogs within each category
    Object.values(category).forEach((blog) => {
      // Add the blog to the blogs array
      blogs.push(blog)
    })
  })

  return orderBlogs(blogs)
}

/**
 * Get related blogs from "learn" entry of the "data/markdown.json" file by passing a slugs array.
 * This will run the `getAllBlogs` function filter the returned array
 * and return a new array with the ones that match the slugs.
 *
 * @param {string[]} slugArray
 * @type {string[]}
 *
 * @returns array of blogs
 * @type BlogProps[]
 *
 * @example getBlogsBySlugArr(relatedArticles)
 */
export const getBlogsBySlugArr = (slugArray: string[]): BlogProps[] => {
  const allBlogs = getAllBlogs()
  const blogs: BlogProps[] = slugArray.flatMap((slug) => allBlogs.filter((blog) => blog.slug === slug))

  return blogs
}

/**
 * Get related blog from "learn" entry of the "data/markdown.json" file by passing a slug.
 * This will run the `getAllBlogs` function, filter the returned array
 * and return a `blog` that matches the slug or `undefined`.
 *
 * @param {string} slug
 * @type {string}
 *
 * @returns a blog or `undefined`
 * @type {BlogProps || undefined}
 *
 * @example getBlogBySlug(blogSlug)
 */
export const getBlogBySlug = (slug: string) => {
  const allBlogs = getAllBlogs()
  const blog = allBlogs.find((blog) => blog.slug === slug)

  return blog
}

/**
 * Get blogs from the "learn" entry of "data/markdown.json" file by a provided category
 * @param {string} category
 * @type {string}
 *
 * @returns array of blogs
 * @type BlogProps[]
 *
 * @example getBlogsByCategory('metabolic-health')
 */
export const getBlogsByCategory = (category: string): BlogProps[] => {
  const blogs: BlogProps[] = []

  if (blogMarkdownData[category]) {
    Object.keys(blogMarkdownData[category]).forEach((blog) => {
      blogs.push(blogMarkdownData[category][blog])
    })
  }

  return orderBlogs(blogs)
}

/**
 * Get blogs from the "learn" entry of "data/markdown.json" file by a provided author
 * @param {string} author
 * @type {string}
 *
 * @returns array of blogs
 * @type BlogProps[]
 *
 * @example getBlogsByAuthor('alasdair')
 */
export const getBlogsByAuthor = (author: string): BlogProps[] => {
  const allBlogs = getAllBlogs()

  const filteredBlogs = allBlogs.filter((blog) => blog.author === author || blog.reviewer === author)

  return orderBlogs(filteredBlogs)
}

/**
 * Get blog categories from the "components/learn/categories.json" file
 * @returns Set of objects: @type{title: string, slug: string}
 *
 * @example getBlogCategories()
 */
export const getBlogCategories = () => {
  const categories: Set<Record<string, any>> = new Set()

  Object.keys(blogCategories).forEach((category) => {
    categories.add({ title: blogCategories[category]['title'], slug: blogCategories[category]['slug'] })
  })

  return categories
}

/**
 * Get the main category from a string
 * @param {string} category
 * @type {string}
 *
 * @returns modified category string
 * @type {string}
 *
 * @example getMainCategory('Metabolic Health') => 'metabolic-health'
 */
export const getMainCategory = (category: string) => {
  return category.toLowerCase().replace(/\s+/g, '-')
}

const orderBlogs = (blogs: BlogProps[]) => orderBy(blogs, [(blog) => blog.updated || blog.published], ['desc'])
